import { CompanyFilterRequest } from "@src/api";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { companyFilterRequestSchema } from "@src/schemas";
import Autocomplete from "./autocomplete";
import { Button, Drawer, TextField } from "@mui/material";
import { useNavigate } from "@tanstack/react-router";
import { Route } from "@src/routes/_auth.index";
import CRMFilterButton from "./crm_filter_button";
import { BaseSyntheticEvent } from "react";
import RangeInput from "./range";
import {
  AffinityLastInteractionTypeQueryOptions,
  AffinityLastInteractorsQueryOptions,
  CompanyColumnQueryOptions,
} from "@src/api_instance";

export default function Filters({
  setFiltersOpen,
  filtersOpen,
}: {
  setFiltersOpen: (open: boolean) => void;
  filtersOpen: boolean;
}) {
  const navigate = useNavigate();
  const filter = companyFilterRequestSchema.validateSync(Route.useSearch(), {
    stripUnknown: true,
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<CompanyFilterRequest>({
    resolver: yupResolver(companyFilterRequestSchema),
    values: filter,
  });

  const reloadPage = (
    filters: CompanyFilterRequest,
    event: BaseSyntheticEvent | null | undefined,
  ) => {
    if (event) {
      event.preventDefault(); // Prevent the default form submission
    }
    console.log("reloadPage", filters);
    navigate({
      search: filters,
    }).catch(console.error);
    setFiltersOpen(false);
  };

  return (
    <Drawer
      anchor="right"
      open={filtersOpen}
      onClose={() => setFiltersOpen(false)}
    >
      <form
        onSubmit={(event) => {
          event.preventDefault(); // Prevent the default form submission
          handleSubmit(reloadPage)().catch(console.error); // Manually trigger form handling
        }}
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          width: 375,
        }}
      >
        <CRMFilterButton
          label="In CRM"
          column_name="in_crm"
          control={control}
        />
        <Autocomplete
          column_name="company_industry"
          control={control}
          label={"Industry"}
          queryOptions={CompanyColumnQueryOptions("company_industry")}
        />
        <Autocomplete
          column_name="company_industry_group"
          control={control}
          label={"Industry Type"}
          queryOptions={CompanyColumnQueryOptions("company_industry_group")}
        />
        <Autocomplete
          column_name="company_location_hq_country"
          control={control}
          label={"Country"}
          queryOptions={CompanyColumnQueryOptions(
            "company_location_hq_country",
          )}
        />
        <Autocomplete
          column_name="company_location_hq_country_group"
          control={control}
          label={"Country Region"}
          queryOptions={CompanyColumnQueryOptions(
            "company_location_hq_country_group",
          )}
        />
        <Autocomplete
          column_name="company_type"
          control={control}
          label={"Company Type"}
          queryOptions={CompanyColumnQueryOptions("company_type")}
        />
        <Autocomplete
          column_name="last_round_type"
          control={control}
          label={"Last Round Funding Type"}
          queryOptions={CompanyColumnQueryOptions("last_round_type")}
        />
        <Autocomplete
          column_name="last_interaction_type"
          control={control}
          label={"Last Interaction Type"}
          queryOptions={AffinityLastInteractionTypeQueryOptions()}
        />
        <Autocomplete
          column_name="last_interactors"
          control={control}
          label={"Last Interactors"}
          queryOptions={AffinityLastInteractorsQueryOptions()}
        />
        <RangeInput
          control={control}
          column_name="company_size_employees_count"
          label="Employee Count"
          validationError={errors.company_size_employees_count}
        />
        <RangeInput
          control={control}
          column_name="growth_pct_1m"
          label="1 Month Growth %"
          validationError={errors.growth_pct_1m}
          adornment="%"
        />
        <RangeInput
          control={control}
          column_name="growth_pct_3m"
          label="3 Month Growth %"
          validationError={errors.growth_pct_3m}
          adornment="%"
        />
        <RangeInput
          control={control}
          column_name="growth_pct_6m"
          label="6 Month Growth %"
          validationError={errors.growth_pct_6m}
          adornment="%"
        />
        <RangeInput
          control={control}
          column_name="company_founded"
          label="Founded Year"
          validationError={errors.company_founded}
          format="0"
        />
        <RangeInput
          control={control}
          column_name="last_round_money_raised"
          label="Last Round Money Raised"
          validationError={errors.last_round_money_raised}
          adornment="$"
        />
        <RangeInput
          control={control}
          column_name="total_money_raised"
          label="Total Money Raised"
          validationError={errors.total_money_raised}
          adornment="$"
        />
        <RangeInput
          control={control}
          column_name="days_since_last_interaction"
          label="Days Since Last Interaction"
          validationError={errors.days_since_last_interaction}
          adornment="days"
        />
        <Controller
          name={"company_name"}
          control={control}
          render={function ({ field: { onChange, value } }) {
            return (
              <TextField
                sx={{ m: 1 }}
                label="Company Name"
                value={value ?? ""}
                onChange={onChange}
              />
            );
          }}
        />
        <Controller
          name={"company_description"}
          control={control}
          render={function ({ field: { onChange, value } }) {
            return (
              <TextField
                sx={{ m: 1 }}
                label="Description"
                value={value ?? ""}
                onChange={onChange}
              />
            );
          }}
        />
        <Button variant="contained" type="submit" sx={{ m: 1 }} color="primary">
          Apply Filters
        </Button>
      </form>
    </Drawer>
  );
}

import Appbar from "@mui/material/AppBar";
import { Box, Toolbar, Typography } from "@mui/material";
import UserMenu from "./components/userMenu";
import QuickSearch from "./components/quickSearch";
const headerHeight = "3.5em";

function Title() {
  return (
    <Typography
      variant="h5"
      sx={{
        fontFamily: '"Hoefler Text", "Times New Roman", Times, serif',
        letterSpacing: "2Px",
        alignItems: "center",
      }}
    >
      ANFA BAZAAR
    </Typography>
  );
}

export default function Layout({ children }: { children: React.ReactNode }) {
  return (
    <div
      style={{
        height: "100vh",
      }}
    >
      <Appbar
        sx={{
          height: `${headerHeight}`,
        }}
      >
        <Toolbar sx={{ justifyContent: "space-between" }}>
          <Title />
          <Box display="flex" alignItems="center">
            <QuickSearch />
            <UserMenu />
          </Box>
        </Toolbar>
      </Appbar>
      <div style={{ height: `${headerHeight}` }} />
      <div
        style={{
          height: `calc(100vh - ${headerHeight})`,
          width: "100%",
          padding: "1em",
        }}
      >
        {children}
      </div>
    </div>
  );
}

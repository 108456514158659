import {
  PaletteMode,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import WbSunnyRoundedIcon from "@mui/icons-material/WbSunnyRounded";
import ModeNightRoundedIcon from "@mui/icons-material/ModeNightRounded";

interface ToggleColorModeProps {
  mode: PaletteMode;
  toggleColorMode: () => void;
}

export function ToggleColorMode({
  mode,
  toggleColorMode,
}: ToggleColorModeProps) {
  return (
    <MenuItem onClick={toggleColorMode}>
      <ListItemIcon>
        {mode === "dark" ? (
          <WbSunnyRoundedIcon fontSize="small" />
        ) : (
          <ModeNightRoundedIcon fontSize="small" />
        )}
      </ListItemIcon>
      <ListItemText>
        {mode === "dark" ? "Light Mode" : "Dark Mode"}
      </ListItemText>
    </MenuItem>
  );
}

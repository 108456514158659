import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Toolbar from "@mui/material/Toolbar";
import FilterListIcon from "@mui/icons-material/FilterList";
import SortIcon from "@mui/icons-material/Sort";
import Typography from "@mui/material/Typography";
import apiInstance from "@src/api_instance";
import { useQuery } from "@tanstack/react-query";
import Filters from "./filters";
import { useState } from "react";
import { CompanyFilterRequest } from "@src/api/models/CompanyFilterRequest";
import { useNavigate } from "@tanstack/react-router";
import BackspaceIcon from "@mui/icons-material/Backspace";
import { Tooltip, useTheme } from "@mui/material";

export default function EnhancedTableToolbar({
  filter,
}: {
  filter: CompanyFilterRequest;
}) {
  const theme = useTheme();
  const navigate = useNavigate();
  const [filtersOpen, setFiltersOpen] = useState(false);
  const { data, isError, isFetching, error } = useQuery({
    queryKey: ["companies_count", filter],
    queryFn: () =>
      apiInstance.countCompaniesCountCompaniesPost({
        companyFilterRequest: filter,
      }),
  });

  const countText = isFetching ? (
    <CircularProgress />
  ) : isError ? (
    <Alert severity="error">{error.message}</Alert>
  ) : (
    <Typography variant="subtitle1">
      Found {data?.toLocaleString()} Companies
    </Typography>
  );

  const clearFilters = () => {
    navigate({ search: {} }).catch(console.error);
  };

  return (
    <>
      <Toolbar
        sx={{
          justifyContent: "space-between",
          borderBottom: `1px solid ${theme.palette.divider}`,
        }}
      >
        <div>
          <Button
            sx={{ mr: 3 }}
            startIcon={<FilterListIcon />}
            onClick={() => setFiltersOpen(!filtersOpen)}
          >
            Filter
          </Button>
          <Tooltip title="Sorting is not yet implemented">
            <span>
              <Button disabled sx={{ ml: 3, mr: 3 }} startIcon={<SortIcon />}>
                Sort
              </Button>
            </span>
          </Tooltip>
          <Button
            color="error"
            sx={{ ml: 3 }}
            startIcon={<BackspaceIcon />}
            onClick={clearFilters}
          >
            Clear
          </Button>
        </div>
        {countText}
      </Toolbar>
      <Filters setFiltersOpen={setFiltersOpen} filtersOpen={filtersOpen} />
    </>
  );
}

/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CombinedCompanyAffinityModel,
  CompanyFilterRequest,
  HTTPValidationError,
  HeadcountModel,
  RangeRequest,
} from '../models/index';
import {
    CombinedCompanyAffinityModelFromJSON,
    CombinedCompanyAffinityModelToJSON,
    CompanyFilterRequestFromJSON,
    CompanyFilterRequestToJSON,
    HTTPValidationErrorFromJSON,
    HTTPValidationErrorToJSON,
    HeadcountModelFromJSON,
    HeadcountModelToJSON,
    RangeRequestFromJSON,
    RangeRequestToJSON,
} from '../models/index';

export interface AddToCrmAddToCrmPostRequest {
    email: string;
    companyName: string;
    domain?: string | null;
}

export interface CountCompaniesCountCompaniesPostRequest {
    companyFilterRequest: CompanyFilterRequest;
}

export interface GetCompaniesCompaniesPostRequest {
    companyFilterRequest: CompanyFilterRequest;
    page?: number;
    pageSize?: number;
}

export interface GetDistinctValuesDistinctGetRequest {
    column?: string;
}

export interface GetHeadcountHeadcountGetRequest {
    companyId: number;
}

export interface GetRangeRangeGetRequest {
    column?: string;
}

/**
 * 
 */
export class DefaultApi extends runtime.BaseAPI {

    /**
     * Add a company to the CRM via domain
     * Add To Crm
     */
    async addToCrmAddToCrmPostRaw(requestParameters: AddToCrmAddToCrmPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters['email'] == null) {
            throw new runtime.RequiredError(
                'email',
                'Required parameter "email" was null or undefined when calling addToCrmAddToCrmPost().'
            );
        }

        if (requestParameters['companyName'] == null) {
            throw new runtime.RequiredError(
                'companyName',
                'Required parameter "companyName" was null or undefined when calling addToCrmAddToCrmPost().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['email'] != null) {
            queryParameters['email'] = requestParameters['email'];
        }

        if (requestParameters['companyName'] != null) {
            queryParameters['company_name'] = requestParameters['companyName'];
        }

        if (requestParameters['domain'] != null) {
            queryParameters['domain'] = requestParameters['domain'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/add_to_crm`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Add a company to the CRM via domain
     * Add To Crm
     */
    async addToCrmAddToCrmPost(requestParameters: AddToCrmAddToCrmPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.addToCrmAddToCrmPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Although fetching data, this is post because the filters are complicated enough for body
     * Count Companies
     */
    async countCompaniesCountCompaniesPostRaw(requestParameters: CountCompaniesCountCompaniesPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters['companyFilterRequest'] == null) {
            throw new runtime.RequiredError(
                'companyFilterRequest',
                'Required parameter "companyFilterRequest" was null or undefined when calling countCompaniesCountCompaniesPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/count_companies`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CompanyFilterRequestToJSON(requestParameters['companyFilterRequest']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Although fetching data, this is post because the filters are complicated enough for body
     * Count Companies
     */
    async countCompaniesCountCompaniesPost(requestParameters: CountCompaniesCountCompaniesPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.countCompaniesCountCompaniesPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Although fetching data, this is post because the filters are complicated enough for body
     * Get Companies
     */
    async getCompaniesCompaniesPostRaw(requestParameters: GetCompaniesCompaniesPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CombinedCompanyAffinityModel>>> {
        if (requestParameters['companyFilterRequest'] == null) {
            throw new runtime.RequiredError(
                'companyFilterRequest',
                'Required parameter "companyFilterRequest" was null or undefined when calling getCompaniesCompaniesPost().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['pageSize'] != null) {
            queryParameters['page_size'] = requestParameters['pageSize'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/companies`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CompanyFilterRequestToJSON(requestParameters['companyFilterRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CombinedCompanyAffinityModelFromJSON));
    }

    /**
     * Although fetching data, this is post because the filters are complicated enough for body
     * Get Companies
     */
    async getCompaniesCompaniesPost(requestParameters: GetCompaniesCompaniesPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CombinedCompanyAffinityModel>> {
        const response = await this.getCompaniesCompaniesPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get distinct values for a column
     * Get Distinct Interaction Types
     */
    async getDistinctInteractionTypesDistinctInteractionTypesGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<string>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/distinct_interaction_types`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Get distinct values for a column
     * Get Distinct Interaction Types
     */
    async getDistinctInteractionTypesDistinctInteractionTypesGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<string>> {
        const response = await this.getDistinctInteractionTypesDistinctInteractionTypesGetRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get distinct values for a column
     * Get Distinct Interactors
     */
    async getDistinctInteractorsDistinctInteractorsGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<string>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/distinct_interactors`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Get distinct values for a column
     * Get Distinct Interactors
     */
    async getDistinctInteractorsDistinctInteractorsGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<string>> {
        const response = await this.getDistinctInteractorsDistinctInteractorsGetRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get distinct values for a column
     * Get Distinct Values
     */
    async getDistinctValuesDistinctGetRaw(requestParameters: GetDistinctValuesDistinctGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<string>>> {
        const queryParameters: any = {};

        if (requestParameters['column'] != null) {
            queryParameters['column'] = requestParameters['column'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/distinct`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Get distinct values for a column
     * Get Distinct Values
     */
    async getDistinctValuesDistinctGet(requestParameters: GetDistinctValuesDistinctGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<string>> {
        const response = await this.getDistinctValuesDistinctGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Return array of headcount values and their timestamps
     * Get Headcount
     */
    async getHeadcountHeadcountGetRaw(requestParameters: GetHeadcountHeadcountGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<HeadcountModel>>> {
        if (requestParameters['companyId'] == null) {
            throw new runtime.RequiredError(
                'companyId',
                'Required parameter "companyId" was null or undefined when calling getHeadcountHeadcountGet().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['companyId'] != null) {
            queryParameters['company_id'] = requestParameters['companyId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/headcount`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(HeadcountModelFromJSON));
    }

    /**
     * Return array of headcount values and their timestamps
     * Get Headcount
     */
    async getHeadcountHeadcountGet(requestParameters: GetHeadcountHeadcountGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<HeadcountModel>> {
        const response = await this.getHeadcountHeadcountGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get range for a numeric column
     * Get Range
     */
    async getRangeRangeGetRaw(requestParameters: GetRangeRangeGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RangeRequest>> {
        const queryParameters: any = {};

        if (requestParameters['column'] != null) {
            queryParameters['column'] = requestParameters['column'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/range`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RangeRequestFromJSON(jsonValue));
    }

    /**
     * Get range for a numeric column
     * Get Range
     */
    async getRangeRangeGet(requestParameters: GetRangeRangeGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RangeRequest> {
        const response = await this.getRangeRangeGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * For columns that are numeric, the range response defined the bounds and if it is enabled
 * @export
 * @interface RangeRequest
 */
export interface RangeRequest {
    /**
     * 
     * @type {number}
     * @memberof RangeRequest
     */
    min?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RangeRequest
     */
    max?: number | null;
}

/**
 * Check if a given object implements the RangeRequest interface.
 */
export function instanceOfRangeRequest(value: object): value is RangeRequest {
    return true;
}

export function RangeRequestFromJSON(json: any): RangeRequest {
    return RangeRequestFromJSONTyped(json, false);
}

export function RangeRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): RangeRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'min': json['min'] == null ? undefined : json['min'],
        'max': json['max'] == null ? undefined : json['max'],
    };
}

export function RangeRequestToJSON(value?: RangeRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'min': value['min'],
        'max': value['max'],
    };
}


/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CompanyModel } from './CompanyModel';
import {
    CompanyModelFromJSON,
    CompanyModelFromJSONTyped,
    CompanyModelToJSON,
} from './CompanyModel';
import type { AffinityModel } from './AffinityModel';
import {
    AffinityModelFromJSON,
    AffinityModelFromJSONTyped,
    AffinityModelToJSON,
} from './AffinityModel';

/**
 * Pydantic model to combine company and affinity data
 * @export
 * @interface CombinedCompanyAffinityModel
 */
export interface CombinedCompanyAffinityModel {
    /**
     * 
     * @type {CompanyModel}
     * @memberof CombinedCompanyAffinityModel
     */
    company: CompanyModel;
    /**
     * 
     * @type {AffinityModel}
     * @memberof CombinedCompanyAffinityModel
     */
    affinity: AffinityModel | null;
    /**
     * 
     * @type {number}
     * @memberof CombinedCompanyAffinityModel
     */
    nextPage: number | null;
    /**
     * 
     * @type {number}
     * @memberof CombinedCompanyAffinityModel
     */
    prevPage: number | null;
}

/**
 * Check if a given object implements the CombinedCompanyAffinityModel interface.
 */
export function instanceOfCombinedCompanyAffinityModel(value: object): value is CombinedCompanyAffinityModel {
    if (!('company' in value) || value['company'] === undefined) return false;
    if (!('affinity' in value) || value['affinity'] === undefined) return false;
    if (!('nextPage' in value) || value['nextPage'] === undefined) return false;
    if (!('prevPage' in value) || value['prevPage'] === undefined) return false;
    return true;
}

export function CombinedCompanyAffinityModelFromJSON(json: any): CombinedCompanyAffinityModel {
    return CombinedCompanyAffinityModelFromJSONTyped(json, false);
}

export function CombinedCompanyAffinityModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): CombinedCompanyAffinityModel {
    if (json == null) {
        return json;
    }
    return {
        
        'company': CompanyModelFromJSON(json['company']),
        'affinity': AffinityModelFromJSON(json['affinity']),
        'nextPage': json['nextPage'],
        'prevPage': json['prevPage'],
    };
}

export function CombinedCompanyAffinityModelToJSON(value?: CombinedCompanyAffinityModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'company': CompanyModelToJSON(value['company']),
        'affinity': AffinityModelToJSON(value['affinity']),
        'nextPage': value['nextPage'],
        'prevPage': value['prevPage'],
    };
}


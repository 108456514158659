import { ThemeOptions } from "@mui/material/styles";

const theme: ThemeOptions = {
  shape: {
    borderRadius: 2.5,
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
};

export const lightTheme: ThemeOptions = {
  palette: {
    mode: "light",
    primary: {
      main: "#ffc107",
    },
    background: {
      default: "rgba(0, 0, 0, 0.08)",
      paper: "#ffffff",
    },
  },
  ...theme,
};

export const darkTheme: ThemeOptions = {
  palette: {
    mode: "dark",
    primary: {
      main: "#ffc107",
    },
  },
  ...theme,
};

import numeral from "numeral";
import {
  FieldError,
  Controller,
  FieldErrorsImpl,
  Merge,
  Control,
} from "react-hook-form";
import {
  Box,
  FormControl,
  FormHelperText,
  Input,
  InputAdornment,
  LinearProgress,
  Tooltip,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { CompanyFilterRequest, RangeRequest } from "@src/api";
import { useQuery } from "@tanstack/react-query";
import apiInstance from "@src/api_instance";
import React from "react";

interface RangeInputProps {
  column_name: keyof CompanyFilterRequest;
  control: Control;
  label: string;
  validationError?: Merge<
    FieldError,
    FieldErrorsImpl<{ min: number; max: number }>
  >;
  format?: string;
  adornment?: string;
}

export default function RangeInput({
  column_name,
  control,
  label,
  validationError,
  format,
  adornment,
}: RangeInputProps) {
  const theme = useTheme();
  const { data, isError, isFetching } = useQuery({
    queryKey: ["range", column_name],
    queryFn: () => apiInstance.getRangeRangeGet({ column: column_name }),
  });

  const numToString = (value: number | null | undefined): string => {
    return value !== null && value !== undefined
      ? numeral(value).format(format)
      : "";
  };

  return (
    <Controller
      name={column_name}
      control={control}
      render={({ field: { value, onChange } }) => {
        const rangeValue = value as RangeRequest;
        return (
          <FormControl
            variant="outlined"
            sx={{
              margin: 1,
              p: 1,
              pr: 2,
              pl: 2,
              border: `1px solid ${theme.palette.grey[700]}`,
              borderRadius: 1,
              "&:hover": {
                borderColor: "white",
              },
            }}
          >
            <FormHelperText sx={{ textAlign: "center", m: 0 }}>
              {label}
            </FormHelperText>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                mt: -1,
                mr: -1,
                ml: -1,
              }}
            >
              <FormControl>
                <Tooltip
                  title={`Set minimum value for ${label}`}
                  placement="bottom"
                  arrow
                >
                  <Input
                    size="small"
                    placeholder={numToString(data?.min)}
                    name="min"
                    value={rangeValue?.min ?? ""}
                    sx={{ mr: 5 }}
                    error={validationError?.min !== undefined}
                    onChange={(e) =>
                      onChange({
                        ...rangeValue,
                        min: e.target.value === "" ? null : e.target.value,
                      })
                    }
                    startAdornment={
                      <InputAdornment position="start">
                        {adornment}
                      </InputAdornment>
                    }
                  />
                </Tooltip>
                {validationError?.min !== undefined && (
                  <FormHelperText error sx={{ mt: 1, textAlign: "left" }}>
                    Provide a number
                  </FormHelperText>
                )}
              </FormControl>
              <FormControl>
                <Tooltip
                  title={`Set maximum value for ${label}`}
                  placement="bottom"
                  arrow
                >
                  <Input
                    size="small"
                    placeholder={numToString(data?.max)}
                    name="max"
                    value={rangeValue?.max ?? ""}
                    sx={{
                      ml: 5,
                      "& .MuiInputBase-input::placeholder": {
                        textAlign: "right",
                      },
                    }}
                    error={validationError?.max !== undefined}
                    onChange={(e) =>
                      onChange({
                        ...rangeValue,
                        max: e.target.value === "" ? null : e.target.value,
                      })
                    }
                    endAdornment={
                      <InputAdornment position="end">
                        {adornment}
                      </InputAdornment>
                    }
                  />
                </Tooltip>
                {validationError?.max !== undefined && (
                  <FormHelperText error sx={{ mt: 1, textAlign: "right" }}>
                    Provide a number
                  </FormHelperText>
                )}
              </FormControl>
            </Box>
            <FormHelperText error sx={{ mt: 1, textAlign: "center" }}>
              {isError ? "Failed to fetch data. Try reloading." : null}
            </FormHelperText>
            {isFetching && <LinearProgress />}
          </FormControl>
        );
      }}
    />
  );
}

import createTheme from "@mui/material/styles/createTheme";
import { darkTheme, lightTheme } from "./theme";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import { useTheme } from "./themeHook";

export function ThemedApp({ children }: { children: React.ReactNode }) {
  const { mode } = useTheme();

  const themeProps = mode === "light" ? lightTheme : darkTheme;
  const theme = createTheme(themeProps);

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline enableColorScheme />
      {children}
    </MuiThemeProvider>
  );
}

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";

import apiInstance from "@src/api_instance";
import { useInfiniteQuery } from "@tanstack/react-query";
import { Alert, LinearProgress, useTheme } from "@mui/material";
import { CombinedCompanyAffinityModel, CompanyFilterRequest } from "@src/api";
import { type UIEvent, useCallback } from "react";
import EnhancedTableToolbar from "./table_toolbar";
import { Route } from "@src/routes/_auth.index";
import { companyFilterRequestSchema } from "@src/schemas";
import CompanyRow from "./company_row";

function TableContent({ filter }: { filter: CompanyFilterRequest }) {
  const {
    data,
    error,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    status,
  } = useInfiniteQuery({
    queryKey: ["companies", filter],
    queryFn: ({ pageParam }: { pageParam: number }) =>
      apiInstance.getCompaniesCompaniesPost({
        companyFilterRequest: filter,
        page: pageParam,
      }),
    initialPageParam: 0,
    getNextPageParam: (lastPage) =>
      lastPage.length > 0 ? lastPage[0].nextPage : null,
    getPreviousPageParam: (firstPage) =>
      firstPage.length > 0 ? firstPage[0].prevPage : null,
  });

  const fetchMoreOnBottomReached = useCallback(
    (containerRefElement?: HTMLDivElement | null) => {
      if (containerRefElement) {
        const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
        //once the user has scrolled within 400px of the bottom of the table, fetch more data if we can
        if (
          scrollHeight - scrollTop - clientHeight < 400 &&
          !isFetching &&
          !isFetchingNextPage &&
          hasNextPage
        ) {
          fetchNextPage().catch(console.error);
        }
      }
    },
    [fetchNextPage, isFetching, hasNextPage, isFetchingNextPage],
  );

  if (status === "pending") {
    return <LinearProgress />;
  }

  if (status == "error") {
    return <Alert severity="error">{error.message}</Alert>;
  }

  const flatData = data.pages.flatMap((companiesPage) => companiesPage);

  if (flatData.length === 0) {
    return (
      <Alert
        variant="outlined"
        sx={{ m: 2, bgcolor: "background.paper" }}
        severity="info"
      >
        No companies found. Try to broaden your search criteria.
      </Alert>
    );
  }

  return (
    <>
      {isFetching && <LinearProgress />}
      <TableContainer
        component={Paper}
        sx={{ height: "calc(100% - 4em)", overscrollBehavior: "contain" }}
        onScroll={(event: UIEvent<HTMLDivElement>) =>
          fetchMoreOnBottomReached(event.target as HTMLDivElement)
        }
      >
        <Table
          sx={{ flex: 1, overflow: "scroll", overscrollBehavior: "contain" }}
        >
          <TableBody>
            {flatData.map((row: CombinedCompanyAffinityModel) => (
              <CompanyRow row={row} key={row.company.company_id} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {isFetching && <LinearProgress />}
    </>
  );
}

export default function CompaniesTable() {
  const theme = useTheme();
  const filter = companyFilterRequestSchema.validateSync(Route.useSearch(), {
    stripUnknown: true,
  });
  console.log("filter", filter);

  return (
    <Paper
      id="table-wrapper"
      sx={{
        height: "100%",
        borderRadius: theme.shape.borderRadius,
      }}
      elevation={3}
    >
      <EnhancedTableToolbar filter={filter} />
      <TableContent filter={filter} />
    </Paper>
  );
}

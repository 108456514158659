import {
  CircularProgress,
  Autocomplete as MuiAutocomplete,
  TextField,
} from "@mui/material";
import { CompanyFilterRequest } from "@src/api";
import { useQuery } from "@tanstack/react-query";
import { Control, Controller } from "react-hook-form";
import { UseQueryOptions } from "@tanstack/react-query";

interface AutocompleteProps {
  column_name: keyof CompanyFilterRequest;
  control: Control;
  label: string;
  queryOptions: UseQueryOptions<string[], Error, string[], string[]>;
}

export default function Autocomplete({
  column_name,
  control,
  label,
  queryOptions,
}: AutocompleteProps) {
  const { data, isError, isFetching, error } = useQuery(queryOptions);

  if (isError) {
    console.error(error);
  }

  return (
    <Controller
      name={column_name}
      control={control}
      render={({ field }) => (
        <MuiAutocomplete
          sx={{ margin: 1 }}
          value={(field.value as string[]) ?? []}
          onChange={(_, newValue) => field.onChange(newValue)}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              error={isError}
              helperText={
                isError ? "Failed to fetch data. Try reloading." : null
              }
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {isFetching ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
          options={data ?? []}
          multiple
          loading={isFetching}
        />
      )}
    />
  );
}

import React, {
  useRef,
  useEffect,
  BaseSyntheticEvent,
  forwardRef,
} from "react";
import {
  Box,
  IconButton,
  Chip,
  Input,
  useTheme,
  Modal,
  Tooltip,
  Divider,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { yupResolver } from "@hookform/resolvers/yup";
import { CompanyFilterRequest } from "@src/api/models/CompanyFilterRequest";
import { Controller, useForm } from "react-hook-form";
import { companyFilterRequestSchema } from "@src/schemas";
import { useNavigate } from "@tanstack/react-router";

// Wrap SearchModal with forwardRef
const SearchModal = forwardRef(function SearchModal(
  { closeModal }: { closeModal: () => void },
  _,
) {
  const theme = useTheme();
  const navigate = useNavigate();
  const inputRef = useRef<HTMLInputElement>(null);

  const modalStyle = {
    position: "absolute",
    top: "20%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "background.paper",
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.divider}`,
    p: 2,
  };

  const { control, handleSubmit } = useForm<CompanyFilterRequest>({
    resolver: yupResolver(companyFilterRequestSchema),
    values: {},
  });

  const executeSearch = (
    filters: CompanyFilterRequest,
    event: BaseSyntheticEvent | null | undefined,
  ) => {
    if (event) {
      event.preventDefault(); // Prevent the default form submission
    }
    console.log("executeSearch", filters);
    navigate({
      search: filters,
    }).catch(console.error);
    closeModal();
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  return (
    <Box sx={modalStyle}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box display="flex" alignItems="center">
          <SearchIcon color="primary" sx={{ marginRight: 1 }} />
          <form
            onSubmit={(event) => {
              event.preventDefault(); // Prevent the default form submission
              handleSubmit(executeSearch)().catch(console.error); // Manually trigger form handling
            }}
          >
            <Controller
              name={"company_name"}
              control={control}
              render={function ({ field: { onChange, value } }) {
                return (
                  <Input
                    name="search"
                    placeholder="Search for a company name"
                    disableUnderline
                    value={value ?? ""}
                    onChange={onChange}
                    inputRef={inputRef}
                    sx={{ width: 250 }}
                  />
                );
              }}
            />
          </form>
        </Box>
        <Chip label="esc" variant="outlined" size="small" sx={{ ml: 2 }} />
      </Box>
      <Divider sx={{ my: 2 }} />
      <Tooltip title="Find company names similar to the provided input">
        <Chip
          label="Company Name Similarity"
          variant="filled"
          size="small"
          sx={{ mr: 1 }}
        />
      </Tooltip>
      <Chip label="Options Coming Soon" variant="outlined" size="small" />
    </Box>
  );
});

export default function QuickSearch() {
  const theme = useTheme();
  const [isOpen, setIsOpen] = React.useState(false);

  React.useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.metaKey && event.key === "k") {
        event.preventDefault();
        setIsOpen(true);
      } else if (event.key === "Escape") {
        setIsOpen(false);
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <>
      <IconButton
        onClick={() => setIsOpen(true)}
        sx={{
          p: 0,
          backgroundColor: theme.palette.background.paper,
          borderRadius: theme.shape.borderRadius,
          height: "1.5em",
          padding: 1,
          m: 1,
        }}
      >
        <SearchIcon color="primary" sx={{ marginRight: 1 }} />
        <Input placeholder="Search..." disableUnderline sx={{ width: 75 }} />
        <Chip label="⌘+k" variant="outlined" size="small" sx={{ ml: 2 }} />
      </IconButton>
      <Modal
        open={isOpen}
        onClose={() => setIsOpen(false)}
        slotProps={{
          backdrop: {
            sx: {
              backdropFilter: "blur(3px)",
              backgroundColor: "rgba(0, 0, 0, 0.2)",
            },
          },
        }}
      >
        <SearchModal closeModal={() => setIsOpen(false)} />
      </Modal>
    </>
  );
}

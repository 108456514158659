/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Pydantic company model
 * @export
 * @interface CompanyModel
 */
export interface CompanyModel {
    /**
     * 
     * @type {number}
     * @memberof CompanyModel
     */
    company_id: number;
    /**
     * 
     * @type {string}
     * @memberof CompanyModel
     */
    company_description: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyModel
     */
    company_domain: string | null;
    /**
     * 
     * @type {number}
     * @memberof CompanyModel
     */
    company_founded: number | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyModel
     */
    company_industry: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyModel
     */
    company_industry_group: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyModel
     */
    company_location_hq_country: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyModel
     */
    company_location_hq_country_group: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyModel
     */
    company_logo: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyModel
     */
    company_name: string;
    /**
     * 
     * @type {number}
     * @memberof CompanyModel
     */
    company_size_employees_count: number | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyModel
     */
    company_type: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyModel
     */
    company_websites_main: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyModel
     */
    company_websites_linkedin: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyModel
     */
    cb_url: string | null;
    /**
     * 
     * @type {number}
     * @memberof CompanyModel
     */
    growth_pct_1m: number | null;
    /**
     * 
     * @type {number}
     * @memberof CompanyModel
     */
    growth_pct_3m: number | null;
    /**
     * 
     * @type {number}
     * @memberof CompanyModel
     */
    growth_pct_6m: number | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyModel
     */
    last_round_type: string | null;
    /**
     * 
     * @type {number}
     * @memberof CompanyModel
     */
    last_round_money_raised: number | null;
    /**
     * 
     * @type {number}
     * @memberof CompanyModel
     */
    total_money_raised: number | null;
}

/**
 * Check if a given object implements the CompanyModel interface.
 */
export function instanceOfCompanyModel(value: object): value is CompanyModel {
    if (!('company_id' in value) || value['company_id'] === undefined) return false;
    if (!('company_description' in value) || value['company_description'] === undefined) return false;
    if (!('company_domain' in value) || value['company_domain'] === undefined) return false;
    if (!('company_founded' in value) || value['company_founded'] === undefined) return false;
    if (!('company_industry' in value) || value['company_industry'] === undefined) return false;
    if (!('company_industry_group' in value) || value['company_industry_group'] === undefined) return false;
    if (!('company_location_hq_country' in value) || value['company_location_hq_country'] === undefined) return false;
    if (!('company_location_hq_country_group' in value) || value['company_location_hq_country_group'] === undefined) return false;
    if (!('company_logo' in value) || value['company_logo'] === undefined) return false;
    if (!('company_name' in value) || value['company_name'] === undefined) return false;
    if (!('company_size_employees_count' in value) || value['company_size_employees_count'] === undefined) return false;
    if (!('company_type' in value) || value['company_type'] === undefined) return false;
    if (!('company_websites_main' in value) || value['company_websites_main'] === undefined) return false;
    if (!('company_websites_linkedin' in value) || value['company_websites_linkedin'] === undefined) return false;
    if (!('cb_url' in value) || value['cb_url'] === undefined) return false;
    if (!('growth_pct_1m' in value) || value['growth_pct_1m'] === undefined) return false;
    if (!('growth_pct_3m' in value) || value['growth_pct_3m'] === undefined) return false;
    if (!('growth_pct_6m' in value) || value['growth_pct_6m'] === undefined) return false;
    if (!('last_round_type' in value) || value['last_round_type'] === undefined) return false;
    if (!('last_round_money_raised' in value) || value['last_round_money_raised'] === undefined) return false;
    if (!('total_money_raised' in value) || value['total_money_raised'] === undefined) return false;
    return true;
}

export function CompanyModelFromJSON(json: any): CompanyModel {
    return CompanyModelFromJSONTyped(json, false);
}

export function CompanyModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyModel {
    if (json == null) {
        return json;
    }
    return {
        
        'company_id': json['company_id'],
        'company_description': json['company_description'],
        'company_domain': json['company_domain'],
        'company_founded': json['company_founded'],
        'company_industry': json['company_industry'],
        'company_industry_group': json['company_industry_group'],
        'company_location_hq_country': json['company_location_hq_country'],
        'company_location_hq_country_group': json['company_location_hq_country_group'],
        'company_logo': json['company_logo'],
        'company_name': json['company_name'],
        'company_size_employees_count': json['company_size_employees_count'],
        'company_type': json['company_type'],
        'company_websites_main': json['company_websites_main'],
        'company_websites_linkedin': json['company_websites_linkedin'],
        'cb_url': json['cb_url'],
        'growth_pct_1m': json['growth_pct_1m'],
        'growth_pct_3m': json['growth_pct_3m'],
        'growth_pct_6m': json['growth_pct_6m'],
        'last_round_type': json['last_round_type'],
        'last_round_money_raised': json['last_round_money_raised'],
        'total_money_raised': json['total_money_raised'],
    };
}

export function CompanyModelToJSON(value?: CompanyModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'company_id': value['company_id'],
        'company_description': value['company_description'],
        'company_domain': value['company_domain'],
        'company_founded': value['company_founded'],
        'company_industry': value['company_industry'],
        'company_industry_group': value['company_industry_group'],
        'company_location_hq_country': value['company_location_hq_country'],
        'company_location_hq_country_group': value['company_location_hq_country_group'],
        'company_logo': value['company_logo'],
        'company_name': value['company_name'],
        'company_size_employees_count': value['company_size_employees_count'],
        'company_type': value['company_type'],
        'company_websites_main': value['company_websites_main'],
        'company_websites_linkedin': value['company_websites_linkedin'],
        'cb_url': value['cb_url'],
        'growth_pct_1m': value['growth_pct_1m'],
        'growth_pct_3m': value['growth_pct_3m'],
        'growth_pct_6m': value['growth_pct_6m'],
        'last_round_type': value['last_round_type'],
        'last_round_money_raised': value['last_round_money_raised'],
        'total_money_raised': value['total_money_raised'],
    };
}


/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AffinityModelLastInteractorsInner } from './AffinityModelLastInteractorsInner';
import {
    AffinityModelLastInteractorsInnerFromJSON,
    AffinityModelLastInteractorsInnerFromJSONTyped,
    AffinityModelLastInteractorsInnerToJSON,
} from './AffinityModelLastInteractorsInner';

/**
 * Copy of CRM data
 * @export
 * @interface AffinityModel
 */
export interface AffinityModel {
    /**
     * 
     * @type {number}
     * @memberof AffinityModel
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof AffinityModel
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof AffinityModel
     */
    domain: string;
    /**
     * 
     * @type {string}
     * @memberof AffinityModel
     */
    last_interaction_date: string | null;
    /**
     * 
     * @type {string}
     * @memberof AffinityModel
     */
    interaction_type: string | null;
    /**
     * 
     * @type {Array<AffinityModelLastInteractorsInner>}
     * @memberof AffinityModel
     */
    last_interactors: Array<AffinityModelLastInteractorsInner> | null;
}

/**
 * Check if a given object implements the AffinityModel interface.
 */
export function instanceOfAffinityModel(value: object): value is AffinityModel {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('domain' in value) || value['domain'] === undefined) return false;
    if (!('last_interaction_date' in value) || value['last_interaction_date'] === undefined) return false;
    if (!('interaction_type' in value) || value['interaction_type'] === undefined) return false;
    if (!('last_interactors' in value) || value['last_interactors'] === undefined) return false;
    return true;
}

export function AffinityModelFromJSON(json: any): AffinityModel {
    return AffinityModelFromJSONTyped(json, false);
}

export function AffinityModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): AffinityModel {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'domain': json['domain'],
        'last_interaction_date': json['last_interaction_date'],
        'interaction_type': json['interaction_type'],
        'last_interactors': (json['last_interactors'] == null ? null : (json['last_interactors'] as Array<any>).map(AffinityModelLastInteractorsInnerFromJSON)),
    };
}

export function AffinityModelToJSON(value?: AffinityModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'name': value['name'],
        'domain': value['domain'],
        'last_interaction_date': value['last_interaction_date'],
        'interaction_type': value['interaction_type'],
        'last_interactors': (value['last_interactors'] == null ? null : (value['last_interactors'] as Array<any>).map(AffinityModelLastInteractorsInnerToJSON)),
    };
}


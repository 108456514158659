import { CompanyFilterRequest } from "@src/api";
import { Control, Controller } from "react-hook-form";
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  useTheme,
} from "@mui/material";

interface FormButtonGroup {
  label: string;
  column_name: keyof CompanyFilterRequest;
  control: Control;
}

export default function CRMFilterButton({
  label,
  column_name,
  control,
}: FormButtonGroup) {
  const theme = useTheme();
  function deserialize(input: string) {
    if (input == "any") {
      return null;
    }
    if (input == "false") {
      return false;
    }
    return true;
  }

  function serialize(input: boolean | null) {
    if (input === null) {
      return "any";
    }
    return input.toString();
  }

  return (
    <Controller
      name={column_name}
      control={control}
      render={function ({ field: { onChange, value } }) {
        return (
          <FormControl
            variant="outlined"
            sx={{
              margin: 1,
              p: 1,
              pr: 2,
              pl: 2,
              border: `1px solid ${theme.palette.grey[700]}`,
              borderRadius: 1,
              "&:hover": {
                borderColor: "white",
              },
            }}
          >
            <FormHelperText sx={{ textAlign: "center", m: 0 }}>
              {label}
            </FormHelperText>
            <RadioGroup
              defaultValue={"any"}
              value={serialize(value as boolean | null)}
              onChange={(e, value) => onChange(deserialize(value))}
              row
              sx={{ justifyContent: "space-between" }}
            >
              <FormControlLabel value="true" control={<Radio />} label="True" />
              <FormControlLabel
                value="false"
                control={<Radio />}
                label="False"
              />
              <FormControlLabel value="any" control={<Radio />} label="Any" />
            </RadioGroup>
          </FormControl>
        );
      }}
    />
  );
}

import { useAuth0 } from "@auth0/auth0-react";
import { Alert } from "@mui/material";
import Button from "@mui/material/Button";
import Layout from "@src/layout";
import { createFileRoute, Outlet } from "@tanstack/react-router";

export const Route = createFileRoute("/_auth")({
  component: () => (
    <Layout>
      <AuthGateway />
    </Layout>
  ),
});

function AuthGateway() {
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();

  console.log("authGateway isAuthenticated: ", isAuthenticated);

  function handleLogin() {
    loginWithRedirect().catch(console.error);
  }

  if (isLoading) {
    return (
      <Alert
        variant="outlined"
        severity="info"
        sx={{ m: 2, bgcolor: "background.paper" }}
      >
        Authenticating...
      </Alert>
    );
  }

  if (!isAuthenticated) {
    return (
      <div
        style={{ width: "100vw", justifyContent: "center", display: "flex" }}
      >
        <Button onClick={handleLogin}>Log In</Button>
      </div>
    );
  }

  return <Outlet />;
}

import { Link, Tooltip } from "@mui/material";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import { CombinedCompanyAffinityModel } from "@src/api/models/CombinedCompanyAffinityModel";

export default function LinkedInCEO({
  row,
}: {
  row: CombinedCompanyAffinityModel;
}) {
  const searchQuery = encodeURIComponent(
    `Provide the URL of the linkedin profile of the CEO of ${row.company.company_domain || row.company.company_name}. Don't make a mistake. Make sure it's the linkedin profile of an individual and not a company page. If it's not, do it again until you find the right URL.`,
  );
  return (
    <Tooltip title={"Perplexity Search for LinkedIn CEO"} arrow>
      <Link
        component="a"
        href={`https://www.perplexity.ai/search?q=${searchQuery}&copilot=true`}
        target="_blank"
        sx={{ height: 25, width: 25 }}
      >
        <AccountBoxIcon />
      </Link>
    </Tooltip>
  );
}

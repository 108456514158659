import { CompanyFilterRequest, Configuration, DefaultApi } from "@src/api";
import { queryOptions } from "@tanstack/react-query";

const backendHost: string = import.meta.env.VITE_BACKEND_HOST as string;
console.log(`backendHost: ${backendHost}`);
const config = new Configuration({
  basePath: backendHost,
});

const apiInstance = new DefaultApi(config);
export default apiInstance;

export function CompanyColumnQueryOptions(
  column_name: keyof CompanyFilterRequest,
) {
  return queryOptions({
    queryKey: ["distinct", column_name],
    queryFn: () =>
      apiInstance.getDistinctValuesDistinctGet({ column: column_name }),
  });
}

export function AffinityLastInteractorsQueryOptions() {
  return queryOptions({
    queryKey: ["affinity_last_interactors"],
    queryFn: () => apiInstance.getDistinctInteractorsDistinctInteractorsGet(),
  });
}

export function AffinityLastInteractionTypeQueryOptions() {
  return queryOptions({
    queryKey: ["affinity_last_interaction_type"],
    queryFn: () =>
      apiInstance.getDistinctInteractionTypesDistinctInteractionTypesGet(),
  });
}

import { ObjectSchema, object, string, number, array, boolean } from "yup";
import { RangeRequest } from "./api/models/RangeRequest";
import { CompanyFilterRequest } from "./api/models/CompanyFilterRequest";

export const rangeRequestSchema: ObjectSchema<RangeRequest> = object({
  min: number().nullable().default(null),
  max: number().nullable().default(null),
});

const ensureArray = (value: null | string | string[]) => {
  if (value === null || value === "" || value === undefined) {
    return null;
  }
  return Array.isArray(value) ? value : [value];
};

const coerceNull = (value: null | string | object) => {
  if (value === null || value === "" || value === undefined) {
    return null;
  }
  return value;
};

// will raise a compile-time type error if the schema does not produce a valid CompanyFilterRequest
export const companyFilterRequestSchema: ObjectSchema<CompanyFilterRequest> =
  object({
    company_description: string().nullable().default(null),
    company_industry: array()
      .of(string().required())
      .transform(ensureArray)
      .nullable()
      .default(null),
    company_industry_group: array()
      .of(string().required())
      .transform(ensureArray)
      .nullable()
      .default(null),
    company_location_hq_country: array()
      .of(string().required())
      .transform(ensureArray)
      .nullable()
      .default(null),
    company_location_hq_country_group: array()
      .of(string().required())
      .transform(ensureArray)
      .nullable()
      .default(null),
    company_name: string().nullable().default(null),
    last_round_type: array()
      .of(string().required())
      .transform(ensureArray)
      .nullable()
      .default(null),
    company_size_employees_count: rangeRequestSchema
      .transform(coerceNull)
      .nullable()
      .default(null),
    company_founded: rangeRequestSchema
      .transform(coerceNull)
      .nullable()
      .default(null),
    company_type: array()
      .of(string().required())
      .transform(ensureArray)
      .nullable()
      .default(null),
    page: number().default(0),
    page_size: number().default(10),
    in_crm: boolean().nullable().default(null),
    last_interactors: array()
      .of(string().required())
      .transform(ensureArray)
      .nullable()
      .default(null),
    last_interaction_type: array()
      .of(string().required())
      .transform(ensureArray)
      .nullable()
      .default(null),
    days_since_last_interaction: rangeRequestSchema
      .transform(coerceNull)
      .nullable()
      .default(null),
    total_money_raised: rangeRequestSchema
      .transform(coerceNull)
      .nullable()
      .default(null),
    last_round_money_raised: rangeRequestSchema
      .transform(coerceNull)
      .nullable()
      .default(null),
    growth_pct_1m: rangeRequestSchema
      .transform(coerceNull)
      .nullable()
      .default(null),
    growth_pct_3m: rangeRequestSchema
      .transform(coerceNull)
      .nullable()
      .default(null),
    growth_pct_6m: rangeRequestSchema
      .transform(coerceNull)
      .nullable()
      .default(null),
  });

import { useAuth0 } from "@auth0/auth0-react";
import { useState } from "react";
import { ToggleColorMode } from "./themeToggle";
import { Logout } from "@mui/icons-material";
import { useTheme } from "../theme/themeHook";
import {
  Avatar,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  useTheme as useMuiTheme,
} from "@mui/material";

export default function UserMenu() {
  const theme = useMuiTheme();
  const { user, logout } = useAuth0();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const { mode, toggleTheme } = useTheme();
  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const logoutAndClose = () => {
    logout().catch(console.error);
    setAnchorEl(null);
  };
  return (
    <>
      <IconButton
        onClick={handleMenu}
        sx={{ height: "1.5em", width: "1.5em", m: 0, p: 0 }}
      >
        <Avatar
          src={user?.picture}
          sx={{
            height: "100%",
            width: "100%",
            borderRadius: theme.shape.borderRadius,
          }}
          variant="square"
        />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem onClick={logoutAndClose}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          <ListItemText>Logout</ListItemText>
        </MenuItem>
        <ToggleColorMode mode={mode} toggleColorMode={toggleTheme} />
      </Menu>
    </>
  );
}
